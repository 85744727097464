@import url('./styles/font.css');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {

  * {
    font-family: 'Montserrat';
  }
}

body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat',
    monospace;
}
