/* Thin */
@font-face {
    font-family: 'Montserrat';
    font-weight: 100;
    font-style: normal;
    src: url('./../assets/font/Montserrat/static/Montserrat-Thin.ttf');
  }
  @font-face {
    font-family: 'Montserrat';
    font-weight: 100;
    font-style: italic;
    src: url('./../assets/font/Montserrat/static/Montserrat-ThinItalic.ttf');
  }
  
  /* Light */
  @font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    font-style: normal;
    src: url('./../assets/font/Montserrat/static/Montserrat-Light.ttf');
  }
  @font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    font-style: italic;
    src: url('./../assets/font/Montserrat/static/Montserrat-LightItalic.ttf');
  }
  
  /* Regular */
  @font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;
    src: url('./../assets/font/Montserrat/static/Montserrat-Regular.ttf');
  }
  @font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: italic;
    src: url('./../assets/font/Montserrat/static/Montserrat-Italic.ttf');
  }
  
  /* Medium */
  @font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: normal;
    src: url('./../assets/font/Montserrat/static/Montserrat-Medium.ttf');
  }
  @font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: italic;
    src: url('./../assets/font/Montserrat/static/Montserrat-MediumItalic.ttf');
  }
  
  /* Bold */
  @font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: normal;
    src: url('./../assets/font/Montserrat/static/Montserrat-Bold.ttf');
  }
  @font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: italic;
    src: url('./../assets/font/Montserrat/static/Montserrat-BoldItalic.ttf');
  }
  
  /* Black */
  @font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    font-style: normal;
    src: url('./../assets/font/Montserrat/static/Montserrat-Black.ttf');
  }
  @font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    font-style: italic;
    src: url('./../assets/font/Montserrat/static/Montserrat-BlackItalic.ttf');
  }
  