/* form */
.ant-form-item .ant-form-item-explain-error {
  color: #ff4d4f;
  font-weight: 400;
  size: 12px;
}

.ant-form-item .ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 18px;
  color: #02296c;
  font-size: 12px;
  font-weight: 600;
}

.adminForm .ant-form-item .ant-form-item-label > label {
  color: #0950a8 !important;
}

/* button */
.ant-btn {
  box-shadow: 0 0 0 0;
}

/* checkbox */
.ant-checkbox-wrapper {
  margin-bottom: 8px;
}

.ant-checkbox-wrapper-checked {
  color: #02296c;
  font-weight: 500;
}

/* tabs */
.signup-tabs .ant-tabs-nav-list {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
}
.signup-tabs .ant-tabs-tab {
  width: 100% !important;
}

.signup-tabs .ant-tabs-tab {
  margin: 0 0 0 0 !important;
  cursor: default !important;
}

.ant-tabs .ant-tabs-tab {
  color: #888;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.06px;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0950a8;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.06px;
}

.ant-tabs .ant-tabs-tabpane {
  color: #5d5d5d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.07px;
}

/* table */

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 0;
  padding: 16px;
  background-color: #fafafc;
}

.ant-pagination .ant-pagination-item-active:hover a {
  color: lightgray;
}

/* .ant-table-wrapper .ant-table-thead >tr>td {
  overflow: hidden !important;
  color: var(--Blue-Brand, #002655) !important;
  text-overflow: ellipsis !important;
  font-family: 'Montserrat' !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 18px !important; /* 150% */
  /* letter-spacing: 0.06px !important; */
/* } */

:where(.css-dev-only-do-not-override-1ne4y9f).ant-table-wrapper .ant-table-thead >tr>th {
  overflow: hidden !important;
  color: var(--Blue-Brand, #002655) !important;
  text-overflow: ellipsis !important;
  font-family: 'Montserrat' !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 18px !important; /* 150% */
  letter-spacing: 0.06px !important;
}

.ant-pagination .ant-pagination-item a {
  color: var(--blue-400, #0950a8);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.06px;
}

.ant-pagination .ant-pagination-item-active a {
  color: white;
  display: block;
  padding: 0 6px;
  background-color: #02269c;
  border-radius: 3px;
}

:where(.css-dev-only-do-not-override-1ne4y9f).ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0px;
}
.admin-table .ant-table-pagination.ant-pagination {
  background-color: white;
}

.admin-table .ant-pagination-item-active a {
  background-color: #5c59e8 !important;
  color: white !important;
  border-color: #5c59e8 !important;
  border-width: 0px !important;
  border-radius: 5px !important;
  border-style: solid !important;
}

.admin-table .ant-pagination-item a {
  background-color: #dedefa;
  color: #5c59e8;
  border-width: 0px !important;
  border-radius: 5px !important;
  border-style: solid !important;
}

/* .admin-table .ant-pagination-disabled .ant-pagination-item-link{
  background-color: #DEDEFA !important;
  color: #5C59E8 !important;
} */

.admin-table .ant-pagination-item-link {
  background-color: #dedefa !important;
  color: #5c59e8 !important;
}

.admin-table .ant-table-tbody > tr > td {
  font-size: 14px;
}

.admin-table .ant-table-row.ant-table-row-selected > .ant-table-cell-row-hover {
  background-color: #f9f9fc !important;
}

/* drawer */
.ant-drawer .ant-drawer-body {
  padding: 0;
}

/* Radio */
.ant-radio-wrapper span.ant-radio + * {
  padding-inline-start: 8px;
  padding-inline-end: 8px;
  color: #2a2e34;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.06px;
}

/* Modal */
.ant-modal .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-top: 10px;
  margin-inline-start: 0px;
  margin-left: 10px
}

/* step */
.ant-steps.ant-steps-small .ant-steps-item-title {
  padding-inline-end: 12px;
  font-size: 12px;
  line-height: 10px;
}

.ant-tabs-nav-list {
  transition: none !important;
}

.steps-custom .ant-steps-item-tail {
  margin-inline-start: 56px;
  padding: 8px 12px !important;
}

.steps-custom .ant-steps-item-title {
  width: max-content;
}

.ant-steps-item-tail::after {
  background-color: lightgray !important;
}

.steps-summary-custom .ant-steps-item-tail {
  margin-inline-start: 56px;
  padding: 5px 15px !important;
}

.steps-summary-custom .ant-steps-item-title {
  width: max-content;
}

.steps-summary-custom .ant-steps-item-title {
  font-size: 12px;
}

.steps-summary-custom .ant-steps-item-icon {
  background-color: #dbdbdb !important;
  border-color: #dbdbdb !important;
}

.steps-custom-return-created .ant-steps-item-tail {
  padding: 4px 16px !important;
}

.steps-custom-return-created .ant-steps-item-icon {
  background-color: #dbdbdb !important;
  border-color: #dbdbdb !important;
}

.steps-custom-return-created .ant-steps-item-content {
  margin-top: 0px !important;
}

.last-point .ant-steps-item-icon {
  background: var(--100, #c5eddb) !important;
  border-color: var(--100, #c5eddb) !important;
}

.last-point .ant-steps-icon {
  color: #3da172 !important;
}

.status-steps .ant-steps-item-tail {
  padding: 4px 16px !important;
}

.status-steps .ant-steps-item-title {
  font-size: 12px;
  line-height: initial;
}

/* segmented */

.segmented-custom {
  border-radius: 8px !important;
  border: 1px solid var(--neutral-gray-gray-100, #e0e2e7) !important;
  background: var(--neutral-white, #fff) !important;
}
.segmented-custom .ant-segmented-item-selected {
  color: var(--primary-primary-600, #4543ae) !important;
}

.segmented-custom .ant-segmented-item {
  font-weight: 500 !important;
  color: var(--neutral-black-black-500, #667085);
}

/* tag */

.custom-tag {
  color: #667085 !important;
  border-radius: 8px !important;
  border: 1px solid var(--neutral-gray-gray-100, #e0e2e7) !important;
  background: var(--neutral-50, #fff) !important;
  display: flex;
  height: 32px;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

/* menu */

.custom-menu {
  border-width: 0 !important;
}

.custom-menu .ant-menu-submenu-title {
  padding-left: 10px !important;
}

/* @media only screen and (min-width: 900px) {
  .custom-menu .ant-menu-title-content {
    margin-left: 10px !important;
  }
} */

.custom-menu .ant-menu-title-content {
  margin-left: 12% !important;
}

.custom-menu .ant-menu-sub.ant-menu-inline {
  background-color: white !important;
}

.custom-menu .ant-menu-submenu-selected > .ant-menu-submenu-title {
  background-color: #ecfbff !important;
  color: #0950a8 !important;
}

.custom-menu .ant-menu-item-selected {
  background-color: #0950a8 !important;
}

.custom-menu .ant-menu-item-selected > .ant-menu-title-content {
  color: white !important;
}

/* collapse */

.admin-collapse .ant-collapse-header-text {
  color: var(--Neutral-500, #2a2e34) !important;
  font-family: 'Montserrat' !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important; /* 155.556% */
  letter-spacing: 0.09px !important;
}
:where(.css-dev-only-do-not-override-1ne4y9f).admin-collapse.ant-collapse-icon-position-end>.ant-collapse-item >.ant-collapse-header .ant-collapse-expand-icon {
    order: 1;
    padding-inline-end: 0;
    padding-inline-start: 12px;
    margin-top: 4px;
}

/* dropdown */

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
  background-color: lightgray;
  font-weight: 600;
}

.ant-drawer-mask {
  background: none !important;
}
