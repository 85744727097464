.control-dots {
  display: none;
}

.custom-panel .ant-collapse-content-box {
  padding: 0 !important;
}

.custom-panel .ant-collapse-expand-icon {
  margin-right: 20px;
}

.custom-crop .ant-upload-list-item-done {
  width: 232px !important;
}

.breadcrumb-select {
  color: var(--Blue-Brand, #002655);
  /* Txt-M/600-semibold */
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 20px; 
  letter-spacing: 0.07px; */
}

.breadcrumb {
  color: var(--Neutral-300, #888);
  /* Txt-M/600-semibold */
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 20px; 
  letter-spacing: 0.07px; */
}

.radio-select .ant-select-selection-item {
  margin-top: 10px;
}

.modalEvidenceProd .ant-btn-default {
  margin-right: 20px;
}


.rainbow-circle {
  background: conic-gradient(
          red, orange, yellow, green, blue, indigo, violet, red
  );
}

.ant-layout {
  background: none;
}

.ant-layout-content {
  margin-top: 80px;
}
